import React from 'react'
import { Controller } from 'react-hook-form'
import { GridContainer, GridItem } from 'components/Grid'
import CustomAutocomplete from 'components/CustomAutocomplete'
import { Button } from 'components/CustomButtons'
import { SLOT_PUBLIC_LABEL, FEE_PUBLIC_LABEL } from 'config/app'
import Drawer from 'components/Drawer'
import {categories} from 'constants/categories'

const SlotDateChooserForm = ({ open, slots, onSave, onClose, hookForm }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = hookForm
  const slot = watch('slot')

  return (
    <Drawer open={open}>
      {open && (
        <form onSubmit={handleSubmit(onSave)}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Controller
                control={control}
                name="category"
                render={({ field }) => (
                  <CustomAutocomplete
                    {...field}
                    label={`Choose a category`}
                    options={categories}
                    optionLabel="name"
                    optionValue="id"
                    error={errors.category}
                    fullWidth
                  />
                )}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Controller
                control={control}
                name="slot"
                render={({ field }) => (
                  <CustomAutocomplete
                    {...field}
                    label={`Choose a ${SLOT_PUBLIC_LABEL}`}
                    options={slots?.data}
                    optionLabel="name"
                    optionValue="id"
                    error={errors.slot}
                    fullWidth
                  />
                )}
              />
            </GridItem>
          </GridContainer>
          {slot && slot?.fees.length > 0 && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Controller
                  control={control}
                  name="fee"
                  render={({ field }) => (
                    <CustomAutocomplete
                      {...field}
                      label={`Choose ${FEE_PUBLIC_LABEL}`}
                      options={slot?.fees}
                      optionLabel="name"
                      optionValue="id"
                      error={errors.fee}
                      fullWidth
                    />
                  )}
                />
              </GridItem>
            </GridContainer>
          )}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button type="submit" color="primary" variant="contained" style={{ marginRight: 10 }}>
                Save
              </Button>
              <Button onClick={onClose}>
                Close
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      )}
    </Drawer>
  )
}
export default SlotDateChooserForm
