export const categories = [
  { id: 1, name: 'နှလုံးအထူးကု ဆရာဝန်ကြီးများ' },
  { id: 2, name: 'နှလုံးခွဲစိတ်အထူးကု ဆရာဝန်ကြီးများ' },
  { id: 3, name: 'အထွေထွေရောဂါကု ဆရာဝန်ကြီးများ' },
  { id: 4, name: 'အသည်း၊ အစာအိမ်နှင့် အူလမ်းကြောင်းဆိုင်ရာ ဆရာဝန်ကြီးများ' },
  { id: 5, name: 'သားဖွား/မီးယပ်အထူးကု  ဆရာဝန်ကြီးများ' },
  { id: 6, name: 'ကလေးအထူးကု ဆရာဝန်ကြီးများ' },
  { id: 7, name: 'ဆီးနှင့် ကျောက်ကပ်အထူးကု ဆရာဝန်ကြီးများ' },
  { id: 8, name: 'အရေပြားအထူးကု ဆရာဝန်ကြီးများ' },
  { id: 9, name: 'ဦးနှောက်နှင့် အာရုံကြောဆိုင်ရာအထူးကု ဆရာဝန်ကြီးများ' },
]
