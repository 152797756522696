import React, { memo } from 'react'
import { navigate } from 'gatsby'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { startOfToday } from 'date-fns'
import BookingForm from 'components/BookingForm'
import Recaptcha from 'components/Recaptcha'
import guestLogin from 'actions/login/guestLogin'
import { book } from 'actions/booking'
import { validateRecaptcha } from 'actions/recaptcha'

const Booking = ({ timetable, onDiscard, afterBooked, slot, fee, editable, location: { search }, customer = null }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const navigatePaymentPage = (booking) => navigate(`/booking/${booking.id}/pay${search ?? ''}`)

  const onSubmit = async (data) => {
    const token = await executeRecaptcha('book')
    let uid
    let loginUser

    if (customer) {
      uid = customer.user.uid
      loginUser = customer.user
    } else {
      const { user } = await guestLogin()
      uid = user.uid
      loginUser = user
    }

    await validateRecaptcha(token)
    await loginUser.getIdToken(true)
    const booking = await book(timetable.id, uid, data, slot, fee, customer?.customer?.toJSON())
    await loginUser.getIdToken(true)
    await loginUser.getIdTokenResult(true)

    setTimeout(async () => {
      if (afterBooked) {
        return await afterBooked(booking, () => {
          return navigatePaymentPage(booking)
        })
      }
      return navigatePaymentPage(booking)
    }, 3000)
  }

  onDiscard =
    onDiscard ??
    (() => {
      navigate('/')
    })

  return (
    <Recaptcha>
      <BookingForm
        timetable={timetable}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        cardStyle={{ boxShadow: 'none' }}
        minDate={startOfToday()}
        customer={customer?.customer?.toFormDefaultJSON()}
        editable={editable}
        loadingAfterSubmitted={true}
      />
    </Recaptcha>
  )
}

export default memo(Booking)
